@import "./antd.css";

/* Change autofill styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--portal-form-input-text-color);
  transition: background-color 5000s ease-in-out 0s;
}

body {
  margin: 0;
  overflow-x: hidden;
}

* {
  font-family: "Inter", Sans-Serif;
}

/*
  this is here because bootstrap sets it to middle, which screws up the alignment of all our icons
  if we don't import bootstrap in our _app.js file anymore, we should remove this
*/
img,
svg {
  vertical-align: baseline !important;
}
.light {
  transition: background-color 0.3s ease;

  /* Theme variables */
  --app-entry-layout-bg: #ffffff;
  --portal-title-color: #262626;
  --portal-form-title-color: #364559;
  --portal-form-input-border: 1px solid #4d6380;
  --portal-form-input-bg-color: rgba(255, 255, 255, 0);
  --portal-form-input-text-color: #364559;
  --portal-form-button-text-color: #ffffff;
  --portal-form-button-bg: #364559;
  --portal-form-button-border: 1px solid #364559;
  --portal-form-or-row-color: #364559;
  --portal-form-footnote-left-color: #364559;
  --portal-form-footnote-right-color: #cf9734;
  --portal-form-privacy-policy-left-text-color: black;
  --portal-form-tooltip-text-color: rgba(0, 0, 0, 0.85);
}

.dark {
  transition: background-color 0.3s ease;

  /* Theme variables */
  --app-entry-layout-bg: linear-gradient(180deg, #364559 0%, #182029 100%);
  --portal-title-color: #ffffff;
  --portal-form-title-color: #ffffff;
  --portal-form-input-border: 0;
  --portal-form-input-bg-color: rgba(77, 99, 128, 0.3);
  --portal-form-input-text-color: rgba(255, 255, 255, 0.7);
  --portal-form-button-text-color: #364559;
  --portal-form-button-bg: linear-gradient(90deg, #9bcfff -18.26%, #8897cb 150.45%);
  --portal-form-button-border: 1px solid #364559;
  --portal-form-or-row-color: #ffffff;
  --portal-form-footnote-left-color: #4d6380;
  --portal-form-footnote-right-color: #ffffff;
  --portal-form-privacy-policy-left-text-color: white;
  --portal-form-tooltip-text-color: white;
}

.centered-container {
  width: fit-content;
  margin: 0 auto;
  text-align: center;
}

.see-more-btn {
  border-radius: 10px;
  color: #1890ff;
  padding: 7px 16px;
  height: fit-content;
  margin-top: 5px;
  width: 150px;
}

@media (max-width: 767px) {
  .centered-container {
    width: fit-content;
  }
}

.ql-editor > * {
  word-break: break-word;
  overflow-wrap: break-word;
}

.react-pdf__Page {
  margin-top: 0px;
  background-color: #eeeeee;
}

.react-pdf__Page__textContent {
  border: 1px solid darkgrey;
  box-shadow: 5px 5px 5px 1px #ccc;
  border-radius: 5px;
  overflow: -moz-hidden-unscrollable;
}

.react-pdf__Page__annotations.annotationLayer {
  padding: 20px;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  overflow: hidden;
}

.ql-mention-list-container {
  max-height: 200px;
  width: 330px;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 12px 0 rgba(30, 30, 30, 0.08);
  z-index: 9001;
  overflow: auto;
}

.ql-mention-loading {
  line-height: 44px;
  padding: 0 20px;
  vertical-align: middle;
  font-size: 16px;
}

.ql-mention-list {
  list-style: none;
  margin: 0;
  padding: 0;
  /* overflow: hidden; */
  overflow: auto;
}

.ql-mention-list-item {
  cursor: pointer;
  padding: 0 20px;
  vertical-align: middle;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 33px;
  display: flex;
  align-items: center;

  color: #595959;
}

.ql-mention-list-item.disabled {
  cursor: auto;
}

.ql-mention-list-item.selected {
  /* background-color: #d3e1eb; */
  background-color: rgba(70, 145, 226, 0.34);

  text-decoration: none;
}

.mention {
  height: 24px;
  width: 65px;
  border-radius: 6px;
  /* background-color: rgba(70, 145, 226, 0.34); */

  padding: 3px 0;
  margin-right: 2px;
  user-select: all;
}

.mention > span {
  margin: 0 3px;
}

/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

/**
  * Show the overflow in IE.
  * 1. Show the overflow in Edge.
  */

button,
input {
  /* 1 */
  overflow: visible;
}

/**
  * Remove the inheritance of text transform in Edge, Firefox, and IE.
  * 1. Remove the inheritance of text transform in Firefox.
  */

button,
select {
  /* 1 */
  text-transform: none;
}

/**
  * Correct the inability to style clickable types in iOS and Safari.
  */

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button,
input[type="submit"],
input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.recharts-tooltip-wrapper {
  z-index: 1000 !important;
}

.cluster-submenu-dropdown {
  padding: 0 !important;
  ul {
    margin: 0;
    padding: 4px 0;
    border-radius: 5px;
    margin-top: -4px;
  }
}
